<template>
  <div class="convention-hall page ppcd">
	  <div  class="tx_top"><img  src="/img/tx/result.png" class="result" @click="back()">
	  	<div  class="tx_top_title">상세 정보</div>
	  </div>
	  <div class="ju">
		  
		  <!-- <div class="top_p">
			  <img  src="/img/nei/top_logo.png" class="top_logo"/>
			  <input  type="text" class="text" v-model="text" placeholder="지역/호텔명을 입력해주세요."/>
			  <div class="yes" @click="init">
				  검색
			  </div>
			  
		  </div>
		 -->
		  
		  <div class="serch2">
			  <img  :src="find.img" class="left"/>
			  <div class="right">
				  <div class="ppt">
					  <div class="line"></div>
					  <div>업소 정보</div>
					    <div class="line"></div>
				  </div>
				  <div class="e">
					  <div class="item">
						  <div class="uu">업소명</div>
						   <div class="zz">{{find.name}}</div>
					  </div>
					  <div class="item">
					  						  <div class="uu">위치</div>
					  						   <div class="zz">{{find.name2}}</div>
					  </div>
					  <div class="item">
					  						  <div class="uu">영업시간</div>
					  						   <div class="zz">{{find.pm}}</div>
					  </div>
					  
					  
				  </div>
			  
					<div class="fei_but">
						<img  src="/img/fei.png" class="fei"/>
						<div>Telegram 상담</div>
					</div>
			  
			  </div>
			  
		  </div>
	
	  </div>
		
		<img src="/img/thumb_ing.gif" class="thumb_ing"/>
	</div>	
	
	
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
		select:[
			'모두',
			'높은가격순',
			'평점높은순'
			
			
			
		],
		select_index:0,
		list:[
			
			
			
		],
		
		text:"",
		selectData:[
			
		],
		find:{
			
		}
		
    };
  },
  methods: {
	  
	  back() {
	    return window.history.back();
	  },
	  serch(){
		  if(this.text==''){
			  this.list2=this.list
			  
		  }else{
			  
			  let data=[]
			  Object.keys(this.list).map(item=>{
			  			//(this.list[item].name2
			  			 if (this.list[item].name2.includes(this.text)) {
			  			     data.push(this.list[item])
			  			 }
			  			 
			  })
			  
			  this.list2=data
			  
		  }
	  },
	  select_data(){
		if(this.select_index==0){
			this.list2=this.list
		}else{
			let data=[]
			Object.keys(this.list).map(item=>{
						 if(this.list[item].index==this.select_index){
							 data.push(this.list[item])
						 }
						 
						 
			})
			
			this.list2=data
		}
	
	  },
	  nato(url){
		   this.$router.push(url);
	  },
	
	 },
  created() {
	
		this.$http({
				 method: "post",
				 url: "goods_find",
				 data:{
					 id:this.$route.query.id,
				 }
		}).then((res) => {
			this.find=res.data
		})
		
		
  },
};
</script>

<style lang="less" scoped>
.ju{
	width: 90%;
	margin: 0 auto;
	margin-bottom: 200px;
	margin-top: 100px;

}
.top_logo{
	width: 200px;
	height: 60px;
}
.text{
	width: 45%;;
	margin-left: 3%;
	border-radius: 5px;
	height: 2rem;
}
.yes{
	background-color: #0f172a;
	color: #fff;
	padding: 3%;
	margin-left: 3%;
	border-radius: 5px;
}
.top_p{
	display: flex;
	justify-content: space-between;
	margin-top: 5%;
	align-items: center;
}
.select{
	display: flex;
	justify-content: space-between;
	margin-top: 5%;
	border-bottom: 1px solid #ccc;
	padding-bottom: 5%;
}
.gd_ee{
	width: 30%;
	height: 500px;
	object-fit: cover;
	border-radius: 13px;
}
.ggsd{
	margin-top: 5%;
	display: flex;
	justify-content: space-between;
}
.fdf3{
	width: 67%;;
	position: relative;
}
.class1{
	color: #a3a6ab;
}
.class2{
	color: #020817;
	font-size: 25px;
	font-weight: 800;
	margin-top: 5%;
}
.class3{
	color: #a3a6ab;
	margin-top: 3%;
}
.class4{
	color: #a3a6ab;
	margin-top: 3%;
	display: flex;
}
.xing{
	width: 25px;
	height: 25px;
	margin-right: 10px;
}
.bian{
	display: flex;
	background-color: #ffad0a;
	display: flex;
	justify-content: center;
	align-items: center;
	
	border-radius: 13px;
	margin-right: 3%;
}
.dibu{
	position: absolute;
	bottom: 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}
.lid{
	border-left: 2px solid #ccc;
	display: flex;
	align-items: center;
	width: 20%;
	
}
.datalistitem4{
	font-size: 14px;
	width: 30%;
	display: flex;
	flex-direction: column;
}
.aaa{
	display: flex;
	justify-content: space-between;
	margin-bottom: 5%;
}
.left{
	width: 40%;
	border-radius: 8px;
}
.right{
	width: 56%;
	
}
.ppt{
	display: flex;
	color: #c3c7cd;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 8%;
	
}
.line{
	width: 100px;
	height: 2px;
	background-color:  #c3c7cd;
}
.serch2{
	display: flex;
	justify-content: space-between;
}
.item{
	display: flex;
	justify-content: space-between;
	margin-bottom: 5%;
	align-items: center;
}
.uu{
	background-color: #f87171;
	color: #fff;
	width: 150px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	font-size: 1rem;
	height: 50px;
	margin-right: 20px;
}
.zz{
	display: flex;
	width: 170px;
	font-size: 1rem;
}
.fei{
	width: 50px;
	height: 50px;
	margin-right: 10px;
}
.fei_but{
	width: 300px;
	height: 80px;
	background-color: #0f172a;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	color: #fff;
	margin: 0 auto;
	margin-top: 8%;
}
.thumb_ing{
	display: block;
	margin: 0 auto;
}
.tx_top{
	background-color: #ccc;
	display: flex;
	
	margin: 0 auto;
	color: #FF1A6D;
	font-size: 1.4rem;
	font-weight: 500;
	align-items: center;
	
	position: relative;
	margin-bottom: 5%;
	padding-top: 3%;
	padding-bottom: 3%;
}
.tx_top_title{
		position: absolute;
		 top: 50%;
		  left: 50%;
		  transform: translate(-50%, -50%);
		  color: #fff;
	
	}
	.result{
		width: 25px;
		height: 40px;
		margin-left: 2%;
	}
	.top_logo{
		width: 100px;
		height: 50px;
	}
</style>