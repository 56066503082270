<template>
  <div class="container page cc2">
   <!-- <van-nav-bar title="로그인 비밀번호 변경" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
      <template #right>
        <span class="nav-right" @click="save()">등록</span>
      </template>
    </van-nav-bar> -->
	<div  class="tx_top"><img  src="/img/tx/result.png" class="result" @click="back()">
		<div  class="tx_top_title">로그인 비밀번호</div>
	</div>
	
    <van-cell-group class="form">
      <div>
		  <div class="name2">기존 비밀번호</div>
		  <van-field
		  				v-model="old_password"
		  				
		  				placeholder="기존 비밀번호를 입력해주세요."
		  				class="li"
		  />
		  	
	  </div>
			  
	  <div>
		  <div class="name2">새 비밀번호</div>
		  <van-field
			v-model="o_new_password"
		   
			placeholder="새로운 비밀번호를 입력해주세요"
			class="li"
		  />
	   </div>
	 <div>  
		<div class="name2">새 비밀번호 확인</div>
      <van-field
        v-model="t_new_password"
      
        placeholder="새 비밀번호를 다시 입력해 주세요"
		class="li"
      />
	  </div>
	  
	  
    </van-cell-group>
	
	<div  class="ti" @click="save()"> 수정 </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      o_new_password: "",
      t_new_password: "",
      old_password: "",
      userInfo: {},
    };
  },
  methods: {
    back() {
      return window.history.back();
    },
    save() {
      if (
        this.o_new_password === "" ||
        this.o_new_password === null ||
        this.o_new_password === undefined
      ) {
        this.$toast.fail("완전하게 채워주세요");
        return false;
      }
      if (
        this.t_new_password === "" ||
        this.t_new_password === null ||
        this.t_new_password === undefined
      ) {
        this.$toast.fail("완전하게 채워주세요");
        return false;
      }
      if (
        this.old_password === "" ||
        this.old_password === null ||
        this.old_password === undefined
      ) {
        this.$toast.fail("완전하게 채워주세요");
        return false;
      }
      if (this.o_new_password !== this.t_new_password) {
        this.$toast("입력한 두 비밀번호가 일치하지 않습니다.");
        return false;
      }
      this.$http({
        method: "get",
        data: {
          old_password: this.old_password,
          new_password: this.o_new_password,
        },
        url: "user_set_loginpw",
      }).then((res) => {
        if (res.code === 200) {
          this.$toast(res.msg);
          setTimeout(() => {
            localStorage.clear();
            this.$router.push("Login");
          }, 500);
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
    getUserInfo() {
      this.$http({
        method: "get",
        url: "user_info",
      }).then((res) => {
        if (res.code === 200) {
          this.userInfo = res.data;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
  },
  created() {
    if (!localStorage.getItem("token")) {
      this.$router.push({ path: "/Login" });
    } else {
      this.getUserInfo();
    }
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/css/base.css";
.van-cell {
  font-size: 35px;
  line-height: 80px;
}
.ti{
	width: 95%;
	margin: 0 auto;
background: linear-gradient(90deg, #FF807B, #FF8FB8);
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	height: 3.5rem;
	margin-top: 5%;
}
.page{
	
	background-image: url(/img/login/bg.png);
	    background-size: 100% 100%;
	    background-repeat: no-repeat;
}
.name2{
		color: #333333;
		font-weight: 600;
	}
	.tx_top{
		display: flex;
		width: 95%;
		margin: 0 auto;
		color: #FF1A6D;
		font-size: 1.4rem;
		font-weight: 500;
		align-items: center;
		margin-top: 5%;
		position: relative;
	}
	.result{
		width: 25px;
		height: 40px;
	}
	.tx_top_title{
		position: absolute;
		 top: 50%;
		  left: 50%;
		  transform: translate(-50%, -50%);
		  color: #fff;
	
	}
	.form{
		
		width: 95%;
		margin: 0 auto;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-color:#fff;
		margin-top: 5%;
		border-radius: 13px;
		padding: 5%;
	}
	.li{
		background-color: inherit !important;
		    background: #F6F6F6;
		    border-radius: 12px;
		   
			
			margin-top: 4.5%;
			margin-bottom: 4.5%;
			color: #fff;
			
	}
	
</style>
<style>
	.cc2 .van-cell::after{
		border: 0 !important;
	}
	.cc2 [class*=van-hairline]::after{
		border: 0 !important;
	}
	.cc2 .van-field__control{
		color: #000;
	}
	.cc2 input{
		background-color: #F6F6F6 !important;
		border-radius: 13px;
		padding-left: 3%;
	}
</style>
