<template>
  <div class="convention-hall page ppcd">
	  <div  class="tx_top"><img  src="/img/tx/result.png" class="result" @click="back()">
	  	<div  class="tx_top_title">상세 정보</div>
	  </div>
	  
	  <div class="ju">
		  <img  :src="find.img" class="nei"/>
		  <div class="lio">
			  LodgingBusiness
		  </div>
		  <div class="hanm">
			 {{find.name1}}
		  </div>
		  <div class="wei">
			  <img  src="/img/nei/wei.png" class="wei"/>
			  {{find.weizhi}}
			  
		  </div>
		  <div class="ok">
			  <div class="z">
				  예약 문의
			  </div>
			  
		  </div>
		  
		  <div class="hanm" >
			  리얼 리뷰
		  </div>
		  
		<div class="lnk" v-for="(rows,index) in list">
			<div class="uu">
						  
						<img :src="rows.img" class="top_logo"/>
						 <div class="date">
							 <div style="font-weight: 800;">
							 				  {{rows.name}}
							 </div>
							 <div style="color: #ccc;">
							 				 후기 날짜 :   {{rows.date}}
							 </div>
						 </div>
						  
			</div>
			
			<van-swipe class="my-swipe"   :show-indicators="false" :loop="false" style="margin-top: 5%;"  width="165">
			  <van-swipe-item  class="ss" v-for="(r,i) in rows.comments">
				 
				  
					<img :src="r.img" class="goods"/>
				 
										   
										   
			  </van-swipe-item>
					
			  
			 
			  
			
			</van-swipe>
			
			<div class="yuan22">
						 {{rows.title}}
			</div>
			
			<div class="textd">
						  
						 {{rows.text}}
			</div>
			
		</div>
		  
	  </div>
		
	</div>	
	
	
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
		list:[
			
			
		],
		find:{
			
		}
	 
    };
  },
  methods: {
	  back() {
	    return window.history.back();
	  },
	 },
  created() {
	let id=this.$route.query.id
	
	this.$http({
			 method: "post",
			 url: "find_goods",
			 data:{
				 id:this.$route.query.id,
			 }
	}).then((res) => {
		this.find=res.data
	})
	
	this.$http({
			 method: "post",
			 url: "gs_liuyan",
			 data:{
				 id:this.$route.query.id,
			 }
	}).then((res) => {
		this.list=res.data
	})
	
	
  },
};
</script>

<style lang="less" scoped>
.ju{
	width: 90%;
	margin: 0 auto;

}
.nei{
	width: 100%;
	border-radius: 5px;
}
.lio{
	color: #49627a;
	margin-top: 5%;
	font-weight: 600;
}
.hanm{
	color: #020817;
	font-weight: 800;
	font-size: 50px;
	margin-top: 2%;
}
.wei{
	color: #81848b;
	margin-top: 2%;
}
.z{
	background-color: #1273e4;
	color: #fff;
	width: 180px;
	display: flex;
	justify-content: center;
	align-items: center;
height: 80px;
	border-radius: 5px;
}
.ok{
	margin-top: 5%;
	display: flex;
	    justify-content: end;
		border-bottom: 1px solid #ccc;
		padding-bottom: 3%;
}
.tx_top{
	background-color: #ccc;
	display: flex;
	
	margin: 0 auto;
	color: #FF1A6D;
	font-size: 1.4rem;
	font-weight: 500;
	align-items: center;
	
	position: relative;
	margin-bottom: 5%;
	padding-top: 3%;
	padding-bottom: 3%;
}
.tx_top_title{
		position: absolute;
		 top: 50%;
		  left: 50%;
		  transform: translate(-50%, -50%);
		  color: #fff;
	
	}
	.result{
		width: 25px;
		height: 40px;
		margin-left: 2%;
	}
	.top_logo{
		width: 100px;
		height: 50px;
	}
	.uu{
		margin-top: 3%;
		
		display: flex;
		    align-items: center;
		
	}
	.date{
		display: flex;
		margin-left: 3%;
		flex-direction: column;
	}
	.yuan22{
		background-color: #9aa9b8;
		width: 100%;
		color: #ffff;
		margin-top: 2%;
		border-radius: 13px;
		text-align: center;
		padding-top: 2px;
		padding-bottom: 2px;
	}
	.textd{
		margin-top: 3.5%;
		margin-bottom: 3.5%;
	}
	.lnk{
		border-bottom: 1px solid #ccc;
		margin-bottom: 5%;
		padding-bottom: 2%;
	}
	.ss{
		margin-right: 3%;
	}
	.goods{
		width: 100%;
		height: 8rem;
	}
</style>