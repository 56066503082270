<template>
  <div class="convention-hall page ppcd" >
	  	


	 <div  class="tx_top"><img  src="/img/tx/result.png" class="result" @click="back()"/>
	 	<div class="tx_top_title"></div>
	 </div>
	 <!-- <div class="ju">
		  
		  <div class="top_p">
			  <img  src="/img/nei/top_logo.png" class="top_logo"/>
			 
			  
		  </div>
		  
		  
		 
	
		
		
	</div>	 -->
	<div style="margin-bottom: 100px;">
	
		<div class="item" v-for="(rows,index) in list">
					 
					  <img  :src="rows.img" class="zz"/>
					  <div class="right">
						  <div class="top_1">
							  <div class="top_1_l">{{rows.name}} ·</div>
							   <div class="top_1_r">{{rows.date}}</div>
						  </div>
						  
						  <div class="top_2">
									{{rows.text}}		  
						  </div>
						  <div class="top_3">
							  
							  <div class="top_3_l">
								  <img  src="/zz/1.png" class="iocn"/>
								  <div class="num">{{rows.im}}		</div>
							  </div>
							  
							  
							  <div class="top_3_l">
									<img  src="/zz/2.png" class="iocn"/>	
													   <div class="num">{{rows.send}}</div>
							  </div>
												  
						  </div>
						  
					  </div>
					  
		</div>
	</div>
	
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
		list:[
			
			
			
		],
		
		
    };
  },
  methods: {
	 back() {
	   return window.history.back();
	 },
	  nato(url){
		   this.$router.push(url);
	  }
	 },
  created() {
	   this.$http({
		 method: "post",
		 url: "liuyan",
	   }).then((res) => {
		this.list=res.data
	   })
  },
};
</script>

<style lang="less" scoped>
.ju{
	width: 100%;
	margin: 0 auto;
	border-bottom: 1px solid #e2e8f0;
	padding-left: 5%;
	padding-right: 5%;

}
.top_logo{
	width: 200px;
	height: 60px;
}
.text{
	width: 45%;;
	margin-left: 3%;
	border-radius: 5px;
	height: 2rem;
}
.yes{
	background-color: #0f172a;
	color: #fff;
	padding: 3%;
	margin-left: 3%;
	border-radius: 5px;
}
.top_p{
	display: flex;
	justify-content: space-between;
	margin-top: 3%;
	align-items: center;
	margin-bottom: 3%;
}
.select{
	display: flex;
	justify-content: space-between;
	margin-top: 5%;
	border-bottom: 1px solid #ccc;
	padding-bottom: 5%;
}
.gd_ee{
	width: 30%;
	height: 500px;
	object-fit: cover;
	border-radius: 13px;
}
.ggsd{
	margin-top: 5%;
	display: flex;
	justify-content: space-between;
}
.fdf3{
	width: 67%;;
	position: relative;
}
.class1{
	color: #a3a6ab;
}
.class2{
	color: #020817;
	font-size: 25px;
	font-weight: 800;
	margin-top: 5%;
}
.class3{
	color: #a3a6ab;
	margin-top: 3%;
}
.class4{
	color: #a3a6ab;
	margin-top: 3%;
	display: flex;
}
.xing{
	width: 25px;
	height: 25px;
	margin-right: 5%;
}
.bian{
	display: flex;
	background-color: #ffad0a;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 73px;
	border-radius: 13px;
	margin-right: 3%;
}
.dibu{
	position: absolute;
	bottom: 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
}
.lid{
	border-left: 2px solid #ccc;
	display: flex;
	align-items: center;
	width: 20%;
	
}
.datalistitem4{
	font-size: 14px;
	width: 30%;
	display: flex;
}
.zz{
	width: 100px;
	height: 100px;
	border-radius: 100%;
}
.item{
	margin-top: 5%;
	    padding-left: 5%;
	    padding-right: 5%;
		display: flex;
	
}
.right{
	display: flex;
	margin-left: 3%;
	width: 70%;
	flex-direction: column;
}
.top_1{
	display: flex;
	width: 100%;
}
.top_1_l{
	font-weight: 700;
}
.top_1_r{
	color: #80838a;
	font-weight: 600;
	margin-left: 3%;
	
}
.top_2{
	margin-top: 3%;
	color: #020817;
}
.top_3{
	margin-top: 3%;
	display: flex;
	justify-content: space-between;
}
.top_3_l{
	display: flex;
	align-items: center;
}
.num{
	margin-left: 30%;
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: center;
}
.tx_top{
	background-color: #ccc;
	display: flex;
	
	margin: 0 auto;
	color: #FF1A6D;
	font-size: 1.4rem;
	font-weight: 500;
	align-items: center;
	
	position: relative;
	margin-bottom: 5%;
	padding-top: 3%;
	padding-bottom: 3%;
}
.tx_top_title{
		position: absolute;
		 top: 50%;
		  left: 50%;
		  transform: translate(-50%, -50%);
		  color: #fff;
	
	}
	.result{
		width: 25px;
		height: 40px;
		margin-left: 2%;
	}
	.top_logo{
		width: 100px;
		height: 50px;
	}
	

</style>