<template>
  <div class="home-container page pp22 pcs">
   <div class="main">
      
      <div class="top">
           <img src="/img/home/nao2.png" style="width:1.5rem;height: 1.5rem;margin-left: 4.5%;margin-right: 2%;"/>

         
       <!--  <van-swipe class="my-swipe"    :show-indicators="false"  autoplay="1500" :vertical="true">
         		 <van-swipe-item>
         			<div style="color:#FF1A6D;font-size: 0.8rem;">공지：왕소명 99번 예약 성공！</div>
         		 </van-swipe-item>	 
         		 <van-swipe-item>
         		 			<div style="color:#FF1A6D;font-size: 0.8rem;">공지：왕소호 예약 성공！</div>
         		 </van-swipe-item>	 
         		 
         		 <van-swipe-item>
         		 			<div style="color:#FF1A6D;font-size: 0.8rem;">공지：왕대 예약 성공！</div>
         		 </van-swipe-item>	 
         		 
         		 
         	
         </van-swipe>	 -->
		<marquee   style="width: 90%;">
			
			 	<div style="color:#FF1A6D;font-size: 0.8rem;">공지：{{notice}}</div>
		</marquee>
		
      </div>

    <div class="ju">

      <!--  <div class="txt">
          서비스 스타
        </div> -->
		
		<div class="npor">
			 <div class="lins"></div>
				<div class="bar">제휴 업소</div>
				<div class="lins"></div>
		</div>	
		<van-swipe class="my-swipe"   :show-indicators="false" :loop="false" style="margin-top: 5%;">
		  <van-swipe-item  class="ss" v-for="(item,index) in adv_data">
			 
			  
				<img :src="item.img" class="goods"/>
			 
									   
									   
		  </van-swipe-item>
		  
	
		  
		
		</van-swipe>


	<!-- <van-swipe class="my-swipe"  width="165"  :show-indicators="false" :loop="false">
	  <van-swipe-item  class="ss" v-for="(rows,num) in top_data">
		 
		  
		  <div class="gs">
			<img :src="rows.bg" class="goods"/>
			<div class="xuhao">0{{(num+1)<10?'0'+(num+1):10}}</div>
		  
			<div class="foot">
		  
				<div class="name">{{rows.name}}</div>
		  
				<div class="tt2">
					
					
					<img :src="rows.icon" class="yuan"/>
					<div class="sui">{{rows.sui}}세/{{rows.shengao}}cm/{{rows.zoom}}kg</div>
				
				</div>
		  
		  
			</div>
		  
		  
		   </div>
		  
		 
								   
								   
	  </van-swipe-item>
	  
	
	</van-swipe> -->

       

		
		
	
		
		
		
		
		
		
	
		
		
		
	


 


    




        <div class="txt">
          실시간 매칭
        </div>


 <div class="top top_l">
	 <!-- autoplay="1000"-->
	 	<van-swipe class="my-swipe swipe-vertical" autoplay="1000"  :vertical="true"   :show-indicators="false" width="100%"  style="height: 2.2rem;width: 100%;" >
			
			<van-swipe-item v-for="num in 50" style="display: flex;justify-content: space-between;">
				<div class="left">
				
				    <img :src="goods_iocn[num%36]" style="width:2.5rem;border-radius: 40%;"/>
				    <div class="phone">{{goods_name[num%7]}}</div>
				
				</div>
                <div class="left" style="width: 28%;">
                    <img src="/img/home/yuan2.png" style="width:1rem;height: 1rem;margin-right: 5%;"/>
               <div class="red">매칭 성공</div>
                    </div>
				
			</van-swipe-item>
			
	
			
			
			
		</van-swipe>	
	 
        


        
      </div>


    <!-- <div class="txt">
          실시간 후기
        </div> -->
<!-- autoplay="1000" -->
	<!-- <van-swipe  class="my-swipe swipe-vertical"   :vertical="true"   :show-indicators="false" width="100%"  style="width: 100%;height: 250px;overflow: scroll;" height="80" >
			<van-swipe-item v-for="num in 20" style="margin-bottom: 5%;">
				
				<div class="top top_l" >
				         <div class="left" style="width:70%;height: 4rem;">
				
				              <img :src="goods_iocn[ num%36]" style="width:3rem;height: 3.5rem;border-radius: 38%;"/>
				              <div class="left left2" >
				                 <div class="a">@{{goods_name[ num%7]}}</div>
				                 <div class="b">메시지: {{Math.ceil(Math.random()*22)}}번호 {{pinglun[ Math.ceil(Math.random()*pinglun.length-1)]}}</div>
				              </div>
				
				         </div>
				
				
				           
				
				                  <div class="left left3">
				                   예약됨
								   <span class="dd3e">0{{20+Math.ceil(Math.random()*10)}}</span>
								   번호
				              </div>
				       
				     </div>
			</van-swipe-item>
		
		</van-swipe>	
	 -->


		   
		   <div class="npor">
		   	 <div class="lins"></div>
		   		<div class="bar">추천 업소</div>
		   		<div class="lins"></div>
		   </div>	
		   
		   <div class="sfd4">
		   	
		   	<div class="ww"  v-for="(rows,index) in index_data" @click="nato('Index_detali?id='+rows.id)"> 
		   		<img  :src="rows.img" class="ww_img"/>
		   		<div class="dif">
		   			<div class="pp">
		   				<img  src="/img/gs_c/t1.png" class="ww_img2"/>
		   				<div class="ted">{{rows.name}}</div>
		   			</div>
		   			<div class="pp">
		   				<img  src="/img/gs_c/t2.png" class="ww_img2"/>
		   				<div class="ted">{{rows.name2}}</div>
		   			</div>
		   			<div class="pp">
		   				<img  src="/img/gs_c/t3.png" class="ww_img2"/>
		   				<div class="ted">{{rows.pm}}</div>
		   			</div>
		   			
		   			
		   		</div>
		   		
		   		
		   	</div>
		   
		   	
		   </div>
	



    </div>







   </div>
  


    
      



  </div>
</template>

<script>
export default {
  data() {
    return {
      notice: "받다......",
	  adv_data:[],
	  index_data:[],
      banners: [{}],
      basicData: [],
      gameitem: [{}, {}, {}, {}],
      movielist_0: [{}, {}, {}, {}],
      movielist_1: [{}, {}, {}, {}, {}, {}, {}, {}],
      isLoading: false,
      movielistSwiperOption: {
        slidesPerView: "auto",
        spaceBetween: 0,
        slidesPerGroup: 1,
      },
      bannerSwiperOption: {
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        speed: 800,
        autoplay: true,
        coverflowEffect: {
          rotate: 50,
          stretch: 10,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        },
      },
	
	  goods_img:[
		  
		'/img/home/1.png',
		'/img/home/11.png',
		'/img/home/22.png',
		'/img/home/201.png',
		'/img/home/gs.png',
		  
	  ],
	  goods_name2:[
		  '장 조심',
		  '두미미',
		  '장 연',
		  '리 리',
		  '조연자',
		  '서미령',
		  '장소가'
		  
	  ],
	  goods_iocn:[
	  		  
	  			'/img/nan/1.jpg',
	  				'/img/nan/2.jpg',
	  				'/img/nan/3.jpg',
	  				'/img/nan/4.jpg',
	  				'/img/nan/5.png',
	  				'/img/nan/6.png',
	  				'/img/nan/7.png',
	  				'/img/nan/8.png',
	  				'/img/nan/9.png',
	  				'/img/nan/10.png',
	  				'/img/nan/11.png',
	  			
	  				'/img/nan/12.png',
	  				'/img/nan/13.png',
	  				'/img/nan/14.png',
	  				'/img/nan/15.png',
	  				'/img/nan/16.png',
	  				'/img/nan/17.png',
	  				'/img/nan/18.png',
	  				'/img/nan/19.png',
	  				'/img/nan/20.png',
	  				'/img/nan/21.png',
	  				'/img/nan/22.png',
	  				
	  				
	  				'/img/nan/23.png',
	  				'/img/nan/24.png',
	  				'/img/nan/25.png',
	  				'/img/nan/26.jpg',
	  				'/img/nan/27.jpg',
	  				'/img/nan/28.jpg',
	  				'/img/nan/29.jpg',
	  				'/img/nan/30.png',
	  				'/img/nan/31.png',
	  				'/img/nan/32.png',
	  				'/img/nan/33.png',
	  				
	  				'/img/nan/34.png',
	  				'/img/nan/35.png',
	  				'/img/nan/36.png',
	  				'/img/nan/37.png',
	  				
	  				 
	  			  
	  			  
	  ],
	  'goods_name':[
		  'sd***55',
		  'asd***332',
		  'cc***45534',
		  'cgfh***221',
		  'ffr***1123',
		  'hgj***3442',
		  'ddr***4432'
	  ],
	  'goods_zz':[
		  '강나영',
		  '강소라',
		  '강지영',
		  '강지원',
		  '김다희',
		  '김민정',
		  '김상미',
		  '민국'
		  
	  ],
	  'pinglun':[
		  '플랫폼에서 제공하는 서비스 덕분에 과정이 매우 순조로웠습니다. ',
		  '플랫폼의 매칭 메커니즘은 매우 정확하여 체험이 좋았습니다.',
		  '플랫폼의 사용자 인터페이스 사용하기가 너무 쉬웠습니다.',
		  '플랫폼의 세심한 서비스에 감사드립니다.  과정이 즐거웠습니다.',
		  '플랫폼의 매니저가 신속하게 대응하여 문제가 잘 해결되었습니다.',
		  '플랫폼에서 제공하는 효율적인 서비스와 만족스러운 경험에 감사드립니다.',
		  '플랫폼의 프라이버시 보호가 잘 되어 있습니다. 감사합니다.',
		  '플랫폼의 서비스에 감사드립니다.  즐거운 경험을 했습니다.',
		  '플랫폼의 매칭 속도가 매우 빠릅니다. ',
		  '다양한 선택과 풍부한 경험을 제공하는 플랫폼에 감사드립니다.',
		  '플랫폼의 아가씨가 너무 이뻐 체험이 만족스럽습니다.',
		  '플랫폼의 안전 조치가 잘 돼 있어 안심됩니다.',
		  '플랫폼의 사용 경험이 매우 원활합니다. ',
		  '플랫폼에서 제공하는 양질의 고객 서비스 덕분에 좋은 경험을 하였습니다. ',
		  '플랫폼의 추천 시스템이 매우 정확하여  감사합니다.',
		  '플랫폼의 세심한 배려에 감사드립니다. 즐거운 경험이었어요.',
		  '플랫폼의 서비스 효율이 매우 높습니다. ',
		  '플랫폼에서 제공하는 친절한 서비스에 감사드립니다. 좋은 경험을 했습니다.',
		  '플랫폼의 사용자 심사 시스템이 매우 엄격하여 안전하다고 느껴집니다.',
		  '플랫폼의 조작 절차가 간결하여 편리하게 사용하였습니다. 감사드립니다.',
		  '플랫폼의 매칭 결과가 매우 만족스럽습니다. 감사합니다.',
		  '플랫폼에서 제공하는 전문적인 조언에 감사드립니다.  즐거운 경험하였습니다.',

	  ],
	  'top_data':[
		  
		  {
			'bg':'/img/index_s/1.jpg',
			 'name':'가현',
			 'icon':'/img/index_s/11.jpg',
			 'sui':22,
			 'shengao':165,
			 'zoom':46
			  
		  },
		  {
		  			'bg':'/img/index_s/2.jpg',
		  			 'name':'가희',
		  			 'icon':'/img/index_s/12.jpg',
					 'sui':26,
					 'shengao':170,
					 'zoom':53
		  			  
		  },
		  {
		  			'bg':'/img/index_s/3.jpg',
		  			 'name':'서윤',
		  			 'icon':'/img/index_s/13.jpg',
					 'sui':21,
					 'shengao':169,
					 'zoom':51
		  			  
		  },
		  {
		  			'bg':'/img/index_s/4.jpg',
		  			 'name':'소윤',
		  			 'icon':'/img/index_s/14.jpg',
					 'sui':23,
					 'shengao':165,
					 'zoom':49
		  			  
		  },
		  {
		  			'bg':'/img/index_s/5.jpg',
		  			 'name':'박주영',
		  			 'icon':'/img/index_s/15.jpg',
					 'sui':25,
					 'shengao':167,
					 'zoom':50
		  			  
		  },
		  {
		  			'bg':'/img/index_s/6.jpg',
		  			 'name':'박지선',
		  			 'icon':'/img/index_s/16.jpg',
					 'sui':22,
					 'shengao':162,
					 'zoom':47
		  			  
		  },
		  {
		  			'bg':'/img/index_s/7.jpg',
		  			 'name':'아진',
		  			 'icon':'/img/index_s/17.jpg',
					 'sui':34,
					 'shengao':170,
					 'zoom':54
		  			  
		  },
		  {
		  			'bg':'/img/index_s/8.jpg',
		  			 'name':'연주',
		  			 'icon':'/img/index_s/18.jpg',
					 'sui':30,
					 'shengao':166,
					 'zoom':49
		  			  
		  },
		  {
		  			'bg':'/img/index_s/9.jpg',
		  			 'name':'주연',
		  			 'icon':'/img/index_s/19.jpg',
					 'sui':24,
					 'shengao':172,
					 'zoom':52
		  			  
		  },
		  {
		  			'bg':'/img/index_s/10.jpg',
		  			 'name':'하나',
		  			 'icon':'/img/index_s/20.jpg',
					 'sui':22,
					 'shengao':168,
					 'zoom':49
		  			  
		  }
		  
		  
	  ]
    };
  },
  methods: {
	  nato(url){
	  		   this.$router.push(url);
	  },
    gotoMenu(router) {
      this.$router.replace(router);
    },
    toLottery(key, id) {
      if (!localStorage.getItem("token")) {
        this.$router.push({ path: "/Login" });
      } else {
        this.$router.push({ path: "/Lottery?key=" + key + "&id=" + id });
      }
    },
    toPlayVideo(id) {
      if (!localStorage.getItem("token")) {
        this.$router.push({ path: "/Login" });
      } else {
        this.$router.push({ path: "/PlayVideo?id=" + id });
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.getBasicConfig();
        this.isLoading = false;
        this.$toast("새로고침 성공");
      }, 500);
    },
    getVideoList() {
      this.$http({
        method: "get",
        data: { id: 0, page: 120 },
        url: "video_list",
      }).then((res) => {
        console.log(res.data.data);
        this.getMovieList_1(res.data.data); //获取首页视频1
      });
    },
    getBasicConfig() {
      this.$http({
        method: "get",
        url: "sys_config",
      }).then((res) => {
        this.basicData = res.data;
        console.log("3", res);
        this.getNotice(this.basicData); //获取公告
        this.getBanner(this.basicData); //获取banner
        this.getGameItem(); //获取首页游戏列表
        this.getMovieList_0(this.basicData); //获取首页视频0
      });
    },

    getNotice(data) {
      console.log("data===🚀===>", data);
      this.notice = data.notice;
    },
	adv(){
		this.$http({
		  method: "post",
		  url: "adv",
		}).then((res) => {
			this.adv_data=res.data
		})
	},
	index_list(){
		this.$http({
		  method: "post",
		  url: "index_list",
		}).then((res) => {
			this.index_data=res.data
		})
	},
	
	
    getGameItem() {
      this.$http({
        method: "get",
        url: "lottery_hot",
      }).then((res) => {
        res.data.forEach((item, index) => {
          if (index === 0) {
            item.name = "데이트 이벤트1";
          } else if (index === 1) {
            item.name = "데이트 이벤트2";
          } else if (index === 2) {
            item.name = "데이트 이벤트3";
          } else if (index === 3) {
            item.name = "데이트 이벤트4";
          }
        });
        this.gameitem = res.data;
      });
    },
    getMovieList_0(data) {
      this.movielist_0 = data.movielist_0;
    },
    getMovieList_1(data) {
      this.movielist_1 = data;
    },
    getBanner(data) {
      this.banners = data.banners;
    },
  },
  mounted() {},
  created() {
    this.getBasicConfig();
    //this.getVideoList();
	this.adv()
	this.index_list()
  },
};
</script>

<style lang="less" scoped>
@notice-bar-size: 30px;
@movie-list-item-bottom-size: 25px;
.folt {
  // overflow: hidden;
  //     text-overflow: ellipsis;
  //     white-space: nowrap;
  width: 170px;
}
.linear-bg {
  height: 200px;
  background: linear-gradient(90deg, #775fd9, #c24491);
}
.home-container {
  
}
.linear-gradient {
  width: 100%;
  height: 2px;
  background: linear-gradient(
    to right,
    rgba(126, 86, 120, 0),
    rgb(230, 195, 161),
    rgba(126, 86, 120, 0)
  );
}
::v-deep .van-notice-bar__left-icon,
.van-notice-bar__right-icon {
  min-width: 40px;
}
.notice-swipe {
  width: calc(100% - 50px);
  height: 85px;
  font-size: @notice-bar-size;
}
::v-deep .van-icon-bullhorn-o::before {
  transform: scale(2.5);
}
.banner {
  width: 100%;
  margin-top: -23%;
}
.banner_swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 620px;
    height: 300px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    background-color: #ffffff;
    background-position: center;
    background-size: cover;
    color: #ffffff;
  }
}
::v-deep .swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0)
  );
}
::v-deep .swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0)
  );
}
::v-deep .van-grid {
  justify-content: space-between;
}
.banner_img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.hot-game {
  width: 100%;
  height: 100%;
}
.hot-title-div {
  width: calc(100% - 50px);
  margin: 0 auto;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.hot-title-div > div:first-child {
  width: 330px;
}
.hot-title-div div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.hot-title-div > div:nth-child(2) span {
  font-size: 20px;
  color: #c24491;
}
.hot-title-div > div:first-child span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 10px;
  font-weight: 700;
  color: #c24491;
}

.hot-title-div > div:first-child span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 28px;
  font-weight: 700;
  color: #c24491;
}
.hot-title-div > div:nth-child(2) span {
  font-size: 25px;
  color: #c24491;
}
.hot-title-div > div:first-child span:before {
  content: "";
  display: block;
  width: 7px;
  height: 28px;
  background-color: #775fd9;
  border-radius: 1px;
  margin-right: 5px;
}
.hot-game .hot-items-div {
  margin-top: -3px;
}
.hot-game .hot-items-div span {
  margin-top: 10px;
  font-size: 28px;
  color: #c24491;
}
.hot-recommend {
  width: 100%;
  flex: 1;
  background-color: #f2f2f5;
}
.movie_swiper {
  .swiper-slide {
    width: 80%;
  }
}
.movie_list_0 {
  width: calc(100% - 50px);
  margin: 0 auto;
}
.movie_cover {
  border-radius: 10px;
  width: 550px;
  height: 330px;
}
.movie_list_0 .movie-list-item-bottom {
  position: relative;
  width: 550px;
  bottom: 43px;
}
.movie_list_0 .movie-list-item-bottom .movie-time-div {
  background-color: rgba(0, 0, 0, 0.4);
}
.movie_list_0 .movie-list-item-bottom > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.movie_list_0 .movie-list-item-bottom .movie-time-div .van-count-down {
  font-size: 28px;
  color: #fff;
}
.movie_list_0 .movie-time-div {
  color: #fff;
  border-radius: 0 0 10px 10px;
}
.movie_list_0 .movie_swiper .hot {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 80px;
}
.movie_list_0 span {
  font-size: 30px;
}
.movie_list_1 {
  display: flex;
  width: calc(100% - 50px);
  margin: 0 auto;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
}
.movie_list_1 .movie-list-item .cover_img {
  border-radius: 10px;
  width: 100%;
  height: 320px;
}
.home-scroll {
  padding-bottom: 110px;
}
.movie_list_1 .movie-list-item {
  margin-bottom: -10px;
  flex:1;
  min-width: 48%;
}

.movie_list_1 .movie-list-item-bottom {
  position: relative;
  width: 100%;
  bottom: 42px;
}
.movie_list_1 .movie-list-item-bottom .movie-time-div {
  background-color: rgba(0, 0, 0, 0.4);
}
.movie_list_1 .movie-list-item-bottom > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.movie_list_1 .movie-list-item-bottom .movie-time-div .van-count-down {
  color: #fff;
}
.movie_list_1 .movie-time-div {
  color: #fff;
  border-radius: 0 0 10px 10px;
  height: 35px;
  padding: 0 20px;
}
.movie_list_1 .movie_swiper .hot {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 5px;
}

.movie_list_1 .movie-list-item .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 180px;
  padding-left: 8px;
  font-size: 25px;
}
.movie_list_1 .movie-list-item .movie-time-div span:last-child {
  overflow: hidden;
  white-space: nowrap;
  width: 0px;
  padding-right: 110px;
  font-size: 22px;
}
.movie_list_0 .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 350px;
  padding-left: 10px;
  font-size: 25px;
}

.hot-recommend-more {
  width: 100%;
  padding-bottom: 20px;
  text-align: center;
  color: #979799;
  font-size: 30px;
}
.hot-items-div .game_item_img {
  width: 100px;
  height: 100px;
}
::v-deep .hot-items-div .game_item_img .van-image__img {
  border-radius: 20px;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}

</style>
<style>
.main{
     
    width:100%;
  
  padding-top: 5%;
}

.top{
  display:flex;
  width:90%;
  margin:0 auto;
align-items: center;
   background-color: #fff;
         background-size: 100% 100%;
         border-radius: 10px;
		 padding-top: 3.5%;
		 padding-bottom: 3.5%;
}
.ju{
    width:90%;
  margin:0 auto;
}
.txt{

font-size: 30px;
color: #FFFFFF;
  margin-top: 5%;
  margin-bottom: 5%;

}
.goods{

  width:100%;
  height:100%;
  border-radius:13px;
  
  position:relative;

}
.xuhao{
 background: linear-gradient(90deg, #FE3279, #FF655D);
  color:#fff;
  position:absolute;
    width:80px;
   height:65px;
   display:flex;
   align-items: center;
    justify-content: center;
    border-top-left-radius: 15px;

        border-bottom-right-radius: 15px;
        top:3%;
		left: 3%;
		font-size: 25px;
}
.gs{
   position:relative;
       width: 100%;
    height: 15.1875rem;
	margin-right: 5%;
 
}
.foot{
  position:absolute;
  bottom:5%;
  width:100%;
  left:5%;
}
.name{
  color:#fff;
  font-size: 1.1rem;
      font-weight: 800;
          margin-bottom: 8%;
		
}
.tt2{
  display:flex;
  
}
.yuan{
  width:65px;
  height:65px;
  border: 2px solid #FFFFFF;
  border-radius: 47%;
}
.sui{
  color:#fff;
      margin-left: 2%;
      font-size: 25px;
      display:flex;
      align-items: center;
}
.top_l{
  display:flex;
  justify-content: space-between;
  padding-left: 5%;
    padding-right: 5%;
    width:104%;
        padding-top: 3%;
    padding-bottom: 3%;
	
}
.left{
   display:flex;
   
    align-items: center;
}
.phone{
  color:#333333;
 margin-left: 8%;
 font-size: 1rem;
}
.red{
  color:#FE2E46;
      font-size: 25px;
}
.left2{
  display:flex;
  flex-direction: column;
  align-items: flex-start;
   margin-left: 5%;
}
.left3{
  color:#666666;
  font-size: 0.75rem;
          height: 2.5rem;
    display: flex;
    align-items: flex-start;
}
.a{
  color:#FF1A6D;
   font-size: 1.2rem;
}
.b{
  color:#333333;
  font-size: 0.6rem;
   margin-top: 8%;
   font-weight: 600;
}
.txt_gs{
	
}
.ss{
	display: flex;
	
}
#app{
  height: 100%;	
}
.pcs{
	background-image: url('/img/login/bg.png');
	       background-size: 100% 100%;
	   background-repeat: no-repeat;
	   
	   height: 100%;
	   overflow: scroll;
}
.dd3e{
	font-size: 35px;
}
.bar{
	color: #a3a9b5;
}
.lins{
	width: 38%;
	height: 2px;
	background-color: #a3a9b5;
}
.npor{
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 8%;
}
.sfd4{
	margin-top: 5%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding-bottom: 180px;
}
.ww{
	width: 48%;
	border-radius: 10px;
	overflow: hidden;
	position: relative;
	margin-bottom: 3.5%;
	height: 13.5rem;
}
.ww_img{
	width: 100%;
	height: 100%;
	font-size: 0;
}
.dif{
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 7.5rem;
	background-color: #08010161;
	display: flex;
	flex-direction: column;
	    justify-content: center;
		

	
}
.pp{
	display: flex;
	
	
	width: 90%;
	margin: 0 auto;
	margin-bottom: 4%;
	
}
.ww_img2{
	width: 40px;
	height: 40px;
	margin-right: 3%;
}
.ted{
	width: 240px;
	color: #fff;
	font-size: 1rem;
	 word-wrap: break-word; /* 允许在单词内换行 */
	  overflow-wrap: break-word; /* 同上，标准属性 */
}
</style>

