<template>
  <div class="convention-hall page ppcd">
	  
	  
		 <div class="tx_top">
			<img  src="/img/tx/result.png" class="result" @click="back()"/>
			<div class="tx_top_title">만남 인증예약</div>
			
			
		 </div>	
	 
	 
	 
	 <div class="shijian">
		
		 <div class="az " @click="onChange(0)">
			 <div :class="index==0?'select':''">모두</div>
			 
		 </div>
		 
		 <div class="az"   v-for="(v, key) in lotteryitem"  @click="onChange(key+1)">
			 
			 <div :class="index==key+1?'select':''">{{v.name}}</div>
			 
		 </div>
		 
		 
	 </div>
	 
	 
	 
	 
	<div>
			<div class="rows">
				<div class="bg"  @click="toLottery(v.key, v.id)"
				  v-for="(v, key) in gameitem"
				  :key="key">
				  
						<div>
				
								<van-image class="game_item_img gs" :src="v.ico" >
							  <template v-slot:loading>
								<van-loading type="circular" />
							  </template>
							</van-image>
						</div>
						
						<div class="rg">
							<div class="class1">{{ v.name }}</div>
					<!--		<div class="class2">{{Math.floor(Math.random() * 10000) + 1}}사람이 논다</div> -->
							
							
						</div>
					
				</div>
				
			</div>
		
	</div>	
	
	
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      gameitem: [{}, {}, {}, {}],
      lotteryitem: [{}, {}, {}, {}],
      isLoading: false,
      activeKey: 0,
	  index:0
    };
  },
  methods: {
	  back() {
	  
			window.history.go(-1);
	  	 // return this.$router.go(-1);
	  },
    onRefresh() {
      setTimeout(() => {
        Toast("새로고침 성공");
        this.isLoading = false;
      }, 500);
    },
    toLottery(key, id) {
		
		this.$http({
		  method: "get",
		  url: "user_info",
		}).then((res) => {
		  if (res.code === 200) {
			  
			  if (res.data.status !== 1) {
			    this.$toast("오프라인 계정");
			    localStorage.clear();
			    this.$router.push({
			      path: "/Login",
			    });
			  }
			  
			  
			 if(res.data.money!='0.00'){
				  if (!localStorage.getItem("token")) {
				    this.$router.push({ path: "/Login" });
				  } else {
				    this.$router.push({ path: "/Lottery?key=" + key + "&id=" + id });
				  }
			  }else{
				  this.$toast("회원 인증 완료 후 이용가능합니다. 인증담당자에게 문의 바랍니다.");
				 
				  
			  }
			  
		  }
		  })
		
		
		
      
    },
    getGameItem() {
      this.$http({
        method: "get",
        url: "lottery_list",
      }).then((res) => {
        res.data.forEach((item, index) => {
          if (index === 0) {
            item.name = "2시간 패키지 인증";
          } else if (index === 1) {
            item.name = "4시간 패키지 인증";
          } else if (index === 2) {
            item.name = "원데이 패키지 인증";
          }
        });
        res.data = res.data.filter(
          (item) => item.name !== "친구" && item.name !== "동성애자"
        );
        this.gameitem = res.data;
      });
    },
    onChange(index) {
	
		if(index==1){
			
			return  this.$router.push({ path: "/Text" });
		}
		
		this.index=index
		this.gameitem=[]
      this.$http({
        method: "get",
        data: { class: index },
        url: "lottery_list",
      }).then((res) => {
        this.gameitem = res.data;
      });
    },
    getLotteryItem() {
      this.$http({
        method: "get",
        url: "lottery_class",
      }).then((res) => {
        res.data.forEach((item, index) => {
          if (index === 0) {
            item.name = "데이트후기";
          } else if (index === 1) {
            item.name = "2 투표";
          } else if (index === 2) {
            item.name = "3번 투표";
          }
        });
        res.data = res.data.filter(
          (item) => item.name !== "친구" && item.name !== "동성애자"
        );
        this.lotteryitem = res.data;
      });
    },
  },
  created() {
    this.getGameItem(); //获取首页游戏列表
    this.getLotteryItem();
  },
};
</script>

<style lang="less" scoped>
.page {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
      background-image: url(/img/login/bg.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
  
}
.nav-bar {
  background: linear-gradient(90deg, #775fd9, #c24491);
  height: 100px;
}
.van-nav-bar {
  line-height: 50px;
}

::v-deep .van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #ffffff;
  font-size: 35px;
}
::v-deep .van-nav-bar__content {
  height: 100px;
}
.van-sidebar {
  width: 180px;
}
.van-sidebar-item--select::before {
  left: 10px;
  height: 44%;
  background-color: #775fd9;
  border-radius: 5px;
  width: 10px;
}

.van-sidebar-item--select {
  color: #775fd9;
  font-size: 35px;
  text-align: center;
}
.van-sidebar-item {
  font-size: 26px;
  text-align: center;
  padding: 40px 10px;
  background-color: #ffffff;
}
.van-sidebar-item--select,
.van-sidebar-item--select:active {
  background-color: #f2f2f5;
}
.convention-item {
  display: flex;
  align-items: center;
  height: calc(100% - 15px);
}
.convention-hall {
  display: flex;
  flex-direction: column;
  bottom: 20px;
  
}
.convention-item .left {
  height: 100%;
  background-color: #fff;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.convention-item .right {
  height: 100%;
  flex: 1;
  background-color: #f2f2f5;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.convention-item .right .list-wrapper {
  padding: 20px 20px;
  min-height: 800px;
}
.convention-item .right .list-wrapper .game_item_img {
  width: 200px;
  height: 200px;
}
.convention-item .right .list-wrapper span {
  margin-top: 10px;
  font-size: 30px;
  color: #c24491;
  line-height: 40px;
}
.convention-item .right .list-wrapper span:last-child {
  margin-top: 10px;
  font-size: 24px;
  color: #000;
}
.van-grid-item {
  padding: 20px;
}
::v-deep .van-grid-item__content--center {
  border-radius: 15px;
  box-sizing: border-box;
}
::v-deep .van-sidebar-item__text {
  width: 100%;
}
::v-deep .van-image__img {
  border-radius: 40px;
}
::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}
.tx_top{
	display: flex;
	width: 95%;
	margin: 0 auto;
	color: #fff;
	font-size: 1.4rem;
	font-weight: 500;
	align-items: center;
	margin-top: 5%;
	position: relative;
}
.result{
	width: 25px;
	height: 40px;
}
.tx_top_title{
	position: absolute;
	 top: 50%;
	  left: 50%;
	  transform: translate(-50%, -50%);

}
.shijian{
	width: 80%;
	margin: 0 auto;
	display: flex;
	color: #fff;
	font-size: 1.1rem;
	margin-top: 5%;
}
.az{
	width: 50%;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	
}
.rows{
	position: relative;
	height: 100%;
	overflow-x: scroll;
}
.bg{
	
	
	
	background-image: url('/img/game/bg.png');
	width: 90%;
	margin: 0 auto;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	margin-top: 5%;
	display: flex;
	padding: 5%;


}
.gs{
	width: 160px;
	height: 160px;
}
.rg{
	margin-left: 5%;
}
.class1{
	color: #fff;
	font-size: 30px;
}
.class2{
	color: #696572;
	font-size: 30px;
	margin-top: 40%;
}
.select{
	background-image: url('/img/game/yuan.png');
	background-size: 100% 40%;
	background-repeat: no-repeat;
	  background-position: bottom;

	
}
</style>
<style>
	.ppcd .van-image__img{
		border-radius: 5px !important;
	}

</style>