<template>
  <div class="convention-hall page ppcd">
	  
	  <div class="ju">
		  
		  <div class="top_p">
			  <img  src="/img/nei/top_logo.png" class="top_logo"/>
			  <input  type="text" class="text" v-model="text"/>
			  <div class="yes" @click="init">
				  검색
			  </div>
			  
		  </div>
		  <div class="select">
			  <div>
				  제휴 업소
			  </div>
			  
			  <div>
			
			  		<select v-model="select_index" @change="init">
						
						<option v-for="(rows,index) in selectData" :value="rows.id">{{rows.name}}</option>
						
					</select>		  
			  </div>
			  
		  </div>
		  
		  <div class="ggsd"  v-for="(rows,index) in list" @click="nato('/detali?id='+rows.id)">
			  <img :src="rows.img" class="gd_ee"  />
			  <div class="fdf3">
				 <div>
					 <div class="class1">{{rows.name}}</div>
					   <div class="class2">{{rows.name1}}</div>
					 					  <div class="class3">{{rows.name2}}</div>
					 					    <div class="class4">
					 							
					 							<div class="bian" style="padding-left: 10px;padding-right: 10px;">
					 								<img  src="/img/nei/xing.png" class="xing"/>
					 								<div style=" color: #000; font-weight: 800;font-size: 12px;">{{rows.xing}}</div>
					 							</div>	
					 							
					 							{{rows.name3}}
					 							</div>
				 </div>
				 
				 <div class="dibu">
					 
					 <div class="aaa">
					 
				 				  <div class="lid">
				 					  <div style="margin-left: 5%; font-size: 14px;">
										  {{rows.r1}}
									  </div>
				 				  </div>
				 				  <div style="font-size: 12px;">
				 					 {{rows.r2}}
				 				  </div>
				 				  <div class="datalistitem4">
									  
									  
									  <div v-if="rows.yuanjia" style="font-weight: 600; font-size: 15px; color: #ccc;   text-decoration: line-through;">
									  										 {{rows.yuanjia}}
									  </div>
									  
				 					  <div style="font-weight: 600; font-size: 15px;">
										 {{rows.price}}
									  </div>
				 					 <!-- <div>원</div> -->
				 				  </div>
								  
						</div>		  
							
						 <div class="aaa">		  
								  <div class="lid">
								  				 					  <div style="margin-left: 5%; font-size: 14px;">
								  										  {{rows.r1_r1}}
								  									  </div>
								  </div>
								  <div style="font-size: 12px;">
								  				 					 {{rows.r2_r2}}
								  </div>
								  <div class="datalistitem4">
									  
									  <div v-if="rows.yuanjia2" style="font-weight: 600; font-size: 15px; color: #ccc;   text-decoration: line-through;">
									  										 {{rows.yuanjia2}}
									  </div>
									  
								  				 					  <div style="font-weight: 600; font-size: 15px;">
								  										 {{rows.price_price}}
								  									  </div>
								  				 					 <!-- <div>원</div> -->
								  </div>
								  
					 </div>			  
				 				  
				 </div>
				  
			  </div>
			  
			
			  
		  </div>
		  
	  </div>
		
		
	</div>	
	
	
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
		select:[
			'모두',
			'높은가격순',
			'평점높은순'
			
			
			
		],
		select_index:0,
		list:[
			
			
			
		],
		
		text:"",
		selectData:[
			
		]
		
    };
  },
  methods: {
	  serch(){
		  if(this.text==''){
			  this.list2=this.list
			  
		  }else{
			  
			  let data=[]
			  Object.keys(this.list).map(item=>{
			  			//(this.list[item].name2
			  			 if (this.list[item].name2.includes(this.text)) {
			  			     data.push(this.list[item])
			  			 }
			  			 
			  })
			  
			  this.list2=data
			  
		  }
	  },
	  select_data(){
		if(this.select_index==0){
			this.list2=this.list
		}else{
			let data=[]
			Object.keys(this.list).map(item=>{
						 if(this.list[item].index==this.select_index){
							 data.push(this.list[item])
						 }
						 
						 
			})
			
			this.list2=data
		}
	
	  },
	  nato(url){
		   this.$router.push(url);
	  },
	  init(){
		 
		  this.$http({
		  		 method: "post",
		  		 url: "goods",
		  		  data: { class_id: this.select_index, text: this.text },
		  }).then((res) => {
				this.list=res.data
		  })
	  }
	 },
  created() {
		this.$http({
				 method: "post",
				 url: "select",
		}).then((res) => {
				this.selectData=res.data
				this.select_index=this.selectData[0].id
				this.init()
		})
		
		
  },
};
</script>

<style lang="less" scoped>
.ju{
	width: 90%;
	margin: 0 auto;
	margin-bottom: 200px;

}
.top_logo{
	width: 100px;
	height: 60px;
}
.text{
	width: 45%;;
	margin-left: 3%;
	border-radius: 5px;
	height: 2rem;
}
.yes{
	background-color: #0f172a;
	color: #fff;
	padding: 3%;
	margin-left: 3%;
	border-radius: 5px;
}
.top_p{
	display: flex;
	justify-content: space-between;
	margin-top: 5%;
	align-items: center;
}
.select{
	display: flex;
	justify-content: space-between;
	margin-top: 5%;
	border-bottom: 1px solid #ccc;
	padding-bottom: 5%;
}
.gd_ee{
	width: 30%;
	height: 500px;
	object-fit: cover;
	border-radius: 13px;
}
.ggsd{
	margin-top: 5%;
	display: flex;
	justify-content: space-between;
}
.fdf3{
	width: 67%;;
	position: relative;
}
.class1{
	color: #a3a6ab;
}
.class2{
	color: #020817;
	font-size: 25px;
	font-weight: 800;
	margin-top: 5%;
}
.class3{
	color: #a3a6ab;
	margin-top: 3%;
}
.class4{
	color: #a3a6ab;
	margin-top: 3%;
	display: flex;
}
.xing{
	width: 25px;
	height: 25px;
	margin-right: 10px;
}
.bian{
	display: flex;
	background-color: #ffad0a;
	display: flex;
	justify-content: center;
	align-items: center;
	
	border-radius: 13px;
	margin-right: 3%;
}
.dibu{
	position: absolute;
	bottom: 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}
.lid{
	border-left: 2px solid #ccc;
	display: flex;
	align-items: center;
	width: 20%;
	
}
.datalistitem4{
	font-size: 14px;
	width: 30%;
	display: flex;
	flex-direction: column;
}
.aaa{
	display: flex;
	justify-content: space-between;
	margin-bottom: 5%;
}
</style>