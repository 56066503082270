<template>
  <div class="mine page">


    <div class="page-bg"></div>
    <div class="wrapper">

	<div class="tx_top">
		<img  src="/img/tx/result.png" class="result" @click="back()"/>
		<div class="tx_top_title">인증 기록</div>
		
		
	</div>	
	
	<van-empty v-if="list.length === 0" description="데이터 사용 가능!" />
	<van-list v-else v-model="loading" loading-text="로드 중" :finished="finished" :immediate-check="false"
	
		finished-text="không còn nữa" @load="onLoad" style="padding-bottom: 10%;">
	<div class="ipu" v-for="(v,key) in list">
		<div class="left" >
			<img  src="/img/youxi/gs.png" class="gs"/>
			<div class="right">
				<div class="red">
					{{v.expect}}
				</div>
				<div class="bai">
					{{v.name}}
				</div>
				<div class="shuai">
					<div class="diansd">
					<!-- 	<img  src="/img/youxi/dian.png" class="dianshu"/>
						<img  src="/img/youxi/dian.png"  class="dianshu"/>
						<img  src="/img/youxi/dian.png" class="dianshu"/> -->
						
						<van-image class="res-img dianshu"
							:src=" v.status === 0 ? 'img/lottery/open_num.gif' : 'img/lottery/shaizi/' +  v.opencode[0]+ '.png' ">
							<template v-slot:loading>
								<van-loading type="spinner" />
							</template>
						</van-image>
						<van-image class="res-img dianshu"
							:src=" v.status === 0 ? 'img/lottery/open_num.gif' : 'img/lottery/shaizi/' + v.opencode[1] + '.png' ">
							<template v-slot:loading>
								<van-loading type="spinner" />
							</template>
						</van-image>
						<van-image class="res-img dianshu"
							:src=" v.status === 0 ? 'img/lottery/open_num.gif' : 'img/lottery/shaizi/' + v.opencode[2] + '.png' ">
							<template v-slot:loading>
								<van-loading type="spinner" />
							</template>
						</van-image>
						
					</div>
					
					<div class="dian">
						<div class="shus" style="margin-top: 2px;;">
							{{v.status === 0 ? 0 : v.opencode[0] + v.opencode[1] + v.opencode[2]}}
						</div>
						<div class="shus">
							{{v.status === 0 ? 0 : (v.opencode[0] + v.opencode[1] + v.opencode[2]) >= 11 && (v.opencode[0] + v.opencode[1] + v.opencode[2]) &lt;= 18 ? "대" : "소"}}
						</div>
						<div class="shus">
							{{v.status === 0 ? 0 : (v.opencode[0] + v.opencode[1] + v.opencode[2]) % 2 === 0 ? "짝" : "홀"}}
						</div>
					</div>
						
				</div>
				
			</div>
			
		</div>
		<div style="background: #FFFFFF;opacity: 0.1;;width: 100%; height: 2px;margin-top: 4%;margin-bottom: 4%;" ></div>
		<div class="left left2">
			
			<div class="li">
				<div class="name">
					
					인증 시간
				</div>
				<div class="tt">
					{{ v.create_time }}
					
				</div>
				
			</div>
			<div  class="li">
				<div class="name">
					
					회차 종료
				</div>
				<div class="tt">
					{{ v.update_time }}
					
				</div>
				
			</div>
			<div  class="li">
				<div class="name">
					
					인증 항목
				</div>
				<div class="tt" style="color: #333333;">
					{{ ((v.data || [])[0] || {}).type }}
					
				</div>
				
			</div>
			<div  class="li">
				<div class="name">
					
					인증 금액
					
				</div>
				<div class="tt" style="color: #FF1A6D; font-weight: 600;">
					{{ v.money }}
					
				</div>
				
			</div>
			
			
		</div>
		
	</div>

	</van-list>
</div>

  </div>
</template>

<script>
	export default {
		data() {
			return {
				show: false,
				isLoading: false,
				finished: false,
				list: [],
				page: 1,
				totalCount:0,
				loading: false,
				refreshing: false,
			};
		},
		methods: {
			back() {
				
				 return this.$router.go(-1);
			},
			onRefresh() {
				setTimeout(() => {
					this.$toast('새로고침 성공');
					this.isLoading = false;
				}, 500);
			},
			onLoad() {
				let timer = setTimeout(() => {
					// if (this.refreshing) {
					// 	this.list = [];
					// 	this.refreshing = false;
					// }
					this.page+=1;
					this.loading = false;
					this.List();
					if (this.list.length == this.totalCount) {
						this.finished = true;
						return
					}
					this.finished && clearTimeout(timer); //清除计时器
				}, 500);
			},
			List() {
				this.$http({
					method: 'get',
					url: 'user_get_game_list',
					data: {
						page: this.page
					},
				}).then(res => {
					if (res.code === 200) {
						let listArr = res.sData.data;
						this.totalCount = res.sData.totalCount;
						this.list = this.page > 1 ? this.list.concat(listArr) : listArr;
						console.log(this.list)
					} else if (res.code === 401) {
						this.$toast(res.msg);
					}
				})
			}
		},
		created() {
			if (!localStorage.getItem('token')) {
				this.$router.push({
					path: '/Login'
				})
			} else {
				this.List();
			}
		}
	};
</script>
<style lang="less" scoped>
	.page {
	    width: 100%;
	  
	    height: 100%;
	
	}
.pay-content {
  width: 80vw;
  height: 530px;
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  ::v-deep .van-cell {
    padding: 4.333vw 2.133vw;
  }

  ::v-deep .van-button--normal {
    padding: 4vw 2vw;
  }
}


.mine {

  bottom: 10px;
  background: #f2f2f5;
 
height: 100% !important;
}
.wrapper{
	background-image:url('/img/login/bg.png');
	background-size: 100% 100%;
	   background-repeat: no-repeat;
	   width: 100%;
	   height: 100%;
	   overflow: scroll;
	   padding-bottom: 8%;
}


.tx_top{
	display: flex;
	width: 95%;
	margin: 0 auto;
	color: #FF1A6D;
	font-size: 1.4rem;
	font-weight: 500;
	align-items: center;
	padding-top: 5%;
	position: relative;
}
.result{
	width: 25px;
	height: 40px;
}
.tx_top_title{
	position: absolute;
	color: #fff;
	  left: 50%;
	  transform: translate( -50%);

}
.yue{
	width: 95%;
	margin: 0 auto;
	background-image: url('/img/tx/yue.png');
	height: 7rem;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	margin-top: 5%;
	display: flex;
	align-items: center;
	padding-left: 5%;
}
.jin_title{
	color: #fff;
}
.jin_jin{
	font-size: 52px;
	color: #fff;
	font-weight: 700;
	margin-top: 10%;
}
.ipu{
	width: 92%;
	margin: 0 auto;

	
	background: #fff;
	border-radius: 8px;
	
	
	margin-top: 5%;
	padding: 4%;
}
.cjp{
	color: #fff;
}
.yuan{
	background: #321B51;
	border-radius: 12px;
	border: 2px solid #BA68F3;
	width: 100%;
	height: 2.8rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 3%;
	padding-right: 6%;
	margin-top: 5%;
}
.hs{
	display: flex;
	align-items: center;
	width: 30%;
	    justify-content: flex-end;
}
.ip_yue{
	background-color: initial !important; 
	height: 100%;
	border: 0;
	color: #999999;
	width: 70%;
}
.close{
	width: 40px;
	height: 40px;
	
}
.KRW{
	color: #fff;

}
.xian2{
	width: 2px;
	height: 100%;
	background-color: #999999;
	height: 1rem;
	margin-left: 10%;
	margin-right: 10%;
}
.ti{
	width: 95%;
	margin: 0 auto;
	background: #FF1A6D;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	height: 3.5rem;
	margin-top: 5%;
}
.text{
	width: 95%;
	margin: 0 auto;
	margin-top: 8%;
	color: #FFFFFF;
	opacity: 0.5;
	font-size: 30px;
}
.rows{
	margin-bottom: 5%;
	margin-top: 5%;
	line-height: 2;
	padding-bottom: 20%;
}
.uu{
	margin-top: 5%;
	color: #b3aaba;
	font-size: 12px;
}
.duan{
	background-color: #FF1A6D;
	width: 8px;
	height: 40px;
	border-radius: 8px;
	margin-right: 3%;
}
.srf{
	display: flex;
	align-items: center;
}
.go{
	width: 15px;
	height: 20px;
}
.bank{
	display: flex;
	color: #fff;
	margin-top: 5%;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #523669;
	padding-bottom: 5%;
}
.bank2{
	border: 0;
}
.lis{
	margin-bottom: 30px;
}
.gs{
	width: 220px;
	height: 200px;
}
.right{
	margin-left: 5%;
}
.red{
	color: #FE2E46;
	    font-size: 1rem;
		font-weight: 700;
}
.bai{
	color: #333333;
	font-size: 1rem;
	margin-top: 5%;
	margin-bottom: 5%;
	font-weight: 600;
}
.shuai{
	display: flex;
	margin-top: 8%;
}
.dian{
	display: flex;
	align-items: center;
	color: #666666;
	margin-left: 8%;
	width: 40%;
}
.dianshu{
	margin-right: 20px;
	width: 1.5rem;
	height: 1.5rem;
}
.right{
	width: 60%;
}
.shus{
	padding-right: 11%;
}
.left2{
	margin-top: 5%;
	display: flex;
	flex-direction: row;
	margin-left: 0;
}
.diansd{
	display: flex;
	align-items: center;
	width: 60%;
}
.name{
	color: #666666;
	font-size: 0.8rem;
	text-align: center;
}
.tt{
	color: #999999;
	margin-top: 15%;
	font-size: 0.9rem;
}
.li{
	width: 25%;
}
</style>
